import React from "react"
import MiscContentPost from "../layouts/MiscContentPost"
import ImageRow from "../layouts/ImageRow"
// import MiscContentPost from "./layouts/MiscContentPost"
// import ImageContentPost from "./layouts/ImageContentPost";

export const getPostLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContentPost":
      return <MiscContentPost {...layout} />
    case "ImageRow":
      return <ImageRow {...layout} />
  }
}
