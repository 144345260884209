import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"
import * as S from "./blogs-navigations.styles"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

const BlogsNavigation = ({ blogId }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          categories {
            nodes {
              name
              slug
            }
          }
          uri
          date(formatString: "MMMM DD, YYYY")
          excerpt
          slug
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `)
  const blogs = staticQuery.allWpPost.nodes
  const blogPosition = blogs.findIndex(blog => blog.id === blogId)

  const previousBlogLink =
    process.env.GATSBY_WEBSITE_URL + "/blog/" + blogs[blogPosition - 1]?.slug
  const nextBlogLink =
    process.env.GATSBY_WEBSITE_URL + "/blog/" + blogs[blogPosition + 1]?.slug
  return (
    <S.Wrapper>
      <S.CustomContainer>
        {blogPosition > 0 && (
          <S.ArrowWrapper url={previousBlogLink} className="left desktop">
            <ArrowBackIcon />
          </S.ArrowWrapper>
        )}
        {blogPosition < blogs.length - 1 && (
          <S.ArrowWrapper url={nextBlogLink} className="right desktop">
            <ArrowForwardIcon />
          </S.ArrowWrapper>
        )}

        <Grid container>
          <Grid item xs={6} sm={6}>
            {blogPosition > 0 && (
              <S.TextWrapper className="left">
                <S.TopTextWrapper>
                  <S.Title>Previous</S.Title>
                  <CustomLink url={previousBlogLink}>
                    <S.BlogTitle>{blogs[blogPosition - 1].title}</S.BlogTitle>
                  </CustomLink>
                </S.TopTextWrapper>
                {blogPosition > 0 && (
                  <S.ArrowWrapper url={previousBlogLink} className="left">
                    <ArrowBackIcon />
                  </S.ArrowWrapper>
                )}
              </S.TextWrapper>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            {blogPosition < blogs.length - 1 && (
              <S.TextWrapper className="right">
                <S.TopTextWrapper>
                  <S.Title className="right">Next</S.Title>
                  <CustomLink url={nextBlogLink}>
                    <S.BlogTitle className="right">
                      {blogs[blogPosition + 1].title}
                    </S.BlogTitle>
                  </CustomLink>
                </S.TopTextWrapper>
                {blogPosition < blogs.length - 1 && (
                  <S.ArrowWrapper url={nextBlogLink} className="right">
                    <ArrowForwardIcon />
                  </S.ArrowWrapper>
                )}
              </S.TextWrapper>
            )}
          </Grid>
        </Grid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default BlogsNavigation
