import styled from "@emotion/styled"

export const Wrapper = styled.div`
  width: 100%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.75rem;
`

// export const SocialWrapper = styled.div`
//   position: relative;
//   top: 0;
// `

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  margin: auto;

  color: ${({ theme }) => theme.palette.secondary.main};

  a:not(.get-started) {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500 !important;
    > span {
      font-weight: 500 !important;
    }
  }

  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 24px;
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(20)};
    margin-top: 0;
  }


  h1 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};
    line-height: 34px;
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(20)};
  }


  h2 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: 34px;
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(20)};
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(25)};
    line-height: 32px;
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(18)};
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(22)};
    line-height: 30px;
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(16)};
  }

  ul, ol {
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(20)};
}

li {
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(10)};
}

.blog {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
}

.blog th {
  background-color: #2F2F42 ;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #d9d9d9;
  text-align: left;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
}

.blog td {
  padding: 10px;
  border: 1px solid #d9d9d9;
  text-align: left;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 24px;
  font-weight: 400;
}



  .wp-caption {
    background: none;
    border: none;
  }
`
