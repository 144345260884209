import styled from "@emotion/styled"
import { Container } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"

export const BlogTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: 27px;
  max-width: 150px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: 55px;
    max-width: unset;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: 35px;
    max-width: unset;
  }

  &.right {
    text-align: right;
    margin-left: auto;
  }
`
export const Title = styled.span`
  opacity: 0.5;
  font-weight: 400;

  &.right {
    text-align: right;
  }
`

export const Wrapper = styled.div`
  padding: 4em 0 6em 0;
`

export const CustomContainer = styled(Container)`
  position: relative;
`

export const ArrowWrapper = styled(CustomLink)`
  width: 60px;
  height: 60px;
  border: 1px solid rgba(252, 74, 90, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;

  svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  &.right {
    right: -80px;
  }
  &.left {
    left: -80px;
  }

  @media only screen and (min-width: 1400px) {
    display: none;
  }

  &.desktop {
    @media only screen and (max-width: 1400px) {
      display: none;
    }
    @media only screen and (min-width: 1400px) {
      position: absolute;
      display: flex;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    svg {
      fill: white;
    }
  }
`

export const TextWrapper = styled.div`
  max-width: 530px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;

  &.left {
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
    align-items: flex-end;
  }
`

export const TopTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
