import styled from "@emotion/styled"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Image = styled(CustomImage)`
  max-height: 536px;
  margin: auto;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-left: 6rem;
  }
  .gatsby-image-wrapper {
    max-height: 536px;
    img {
      object-fit: contain !important;
    }
  }
`
