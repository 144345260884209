import styled from "@emotion/styled"
import Container from "@mui/material/Container"

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 4.5rem;
    flex-direction: row;
  }
`

export const LayoutsWrapper = styled.div`
  //width: 100%;
`
