import React from "react"
import * as S from "./misc-content-post.styles.jsx"
import parse from "html-react-parser"
import CustomButton from "../../components/custom-button/custom-button.component"

const MiscContentPost = ({ content, button }) => {
  if (!content) return null
  return (
    <S.Wrapper className="first-wrapper">
      <S.ContentWrapper>
        {parse(content)}
        {button && (
          <S.ButtonWrapper>
            <CustomButton href={button.url} className="get-started">
              {button.title}
            </CustomButton>
          </S.ButtonWrapper>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
export default MiscContentPost
