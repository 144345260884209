import styled from "@emotion/styled"
import SectionWrapper from "../section-wrapper/section-wrapper.component"
import CustomImage from "../custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  padding-bottom: 0;
  color: ${({ theme }) => theme.palette.secondary.main};
  position: relative;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: ${({ theme }) => theme.typography.pxToRem(57)};
  }
`

export const Date = styled.span`
  opacity: 0.5;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 21px;
  }
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(35)};
  line-height: 45px;
  margin-top: ${({ theme }) => theme.typography.pxToRem(8)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(15)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(50)};
    line-height: ${({ theme }) => theme.typography.pxToRem(65)};
    margin-bottom: 1rem;
  }
`

export const Image = styled(CustomImage)`
  //height: 395px;
  margin: 1em 0;
  img {
    border-radius: 8px;
    //height: 395px;
    object-fit: contain !important;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    //height: 536px;
    img {
      border-radius: 8px;
      //height: 536px;
      object-fit: contain !important;
    }
  }
`
