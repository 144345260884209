import React from "react"
import { Container } from "@mui/material"
import * as S from "./image-row.styles"
import { Fade } from "react-awesome-reveal"

const ImageRow = ({ image }) => {
  return (
    <div>
      <S.Image img={image} />
    </div>
  )
}

export default ImageRow
