import styled from "@emotion/styled"
import { darken } from "@mui/system"
import { Alert } from "@mui/material"
import Container from "@mui/material/Container"

export const Wrapper = styled.div`
  min-width: 180px;
  padding: 2rem 0;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 0;
  }
`

export const CustomContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    position: sticky;
    top: 6rem;
  }
`

export const CopyWrapper = styled.div`
  display: flex;
  gap: 0.5em;
  // span {
  //   font-weight: 600;
  //   color: ${({ theme }) => theme.palette.secondary.main};
  // }
`

export const CustomAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  margin: auto;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 630px;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const IconRow = styled.div`
  display: flex;
  gap: 0.5em;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 2em;
  }
`
export const IconWrapper = styled.a`
  text-decoration: none;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, color }) =>
      darken(
        theme.palette[color]
          ? theme.palette[color].main
          : theme.palette.primary.main,
        0.1
      )};
  }

  svg {
    width: 19px;
    height: 19px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 32px;
    height: 32px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`
