import React from "react"
import * as S from "./share-social-media-icons.styles"
import CustomButton from "../custom-button/custom-button.component"
import FacebookIcon from "../../assets/facebook_blog.svg"
import LinkedInIcon from "../../assets/linkedin_blog.svg"
import TwitterIcon from "../../assets/twitter.svg"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { isBrowser } from "../../utils/utils"
import Snackbar from "@mui/material/Snackbar"

const ShareSocialMediaIcons = ({ show, slug }) => {
  const [alertOpen, setAlertOpen] = React.useState(false)
  const CopyToClipboard = toCopy => {
    if (isBrowser) {
      const el = document.createElement(`textarea`)
      el.value = toCopy
      el.setAttribute(`readonly`, ``)
      el.style.position = `absolute`
      el.style.left = `-9999px`
      document.body.appendChild(el)
      el.select()
      document.execCommand(`copy`)
      document.body.removeChild(el)
    }
  }
  return (
    <S.Wrapper hiddeOnMobile={show}>
      <S.CustomContainer disableGutters={true}>
        <S.InfoWrapper>
          <CustomButton
            className="darkBorder"
            onClick={() => {
              CopyToClipboard(isBrowser && window.location.href)
              setAlertOpen(true)
            }}
          >
            <S.CopyWrapper>
              <ContentCopyIcon />
              <span>Copy Link</span>
            </S.CopyWrapper>
          </CustomButton>
          <S.IconRow>
            <S.IconWrapper
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${
                process.env.GATSBY_WEBSITE_URL + "/blog/" + slug
              }`}
              target={"_blank"}
            >
              <LinkedInIcon />
            </S.IconWrapper>
            <S.IconWrapper
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                process.env.GATSBY_WEBSITE_URL + "/blog/" + slug
              }`}
              target={"_blank"}
            >
              <FacebookIcon />
            </S.IconWrapper>
            <S.IconWrapper
              href={`https://twitter.com/intent/tweet?&url=${
                process.env.GATSBY_WEBSITE_URL + "/blog/" + slug
              }`}
              target={"_blank"}
            >
              <TwitterIcon />
            </S.IconWrapper>
          </S.IconRow>
        </S.InfoWrapper>
      </S.CustomContainer>

      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <S.CustomAlert
          onClose={() => setAlertOpen(false)}
          severity="success"
          variant="filled"
        >
          Link copied
        </S.CustomAlert>
      </Snackbar>
    </S.Wrapper>
  )
}

export default ShareSocialMediaIcons
