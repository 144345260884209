import styled from "@emotion/styled"
import { Dialog, IconButton } from "@mui/material"
import Vector from "../../assets/demo-vector.svg"
export const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 32px;
    width: 100%;
    overflow: hidden;
    ${({ theme }) => theme.breakpoints.down("md")} {
      margin: 0.75rem;
    }
  }
  @media only screen and (max-width: 460px) {
    .MuiGrid-item:first-child {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`
export const Content = styled.div`
  background: ${({ theme }) => theme.palette.secondary.main};
  padding: 2.5rem 0 0 1.25rem;
  border-radius: 32px;
  h3,
  p {
    color: white;
  }
  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    line-height: 20px;
    padding-bottom: 1rem;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-left: 3.5rem;
    p {
      font-size: ${({ theme }) => theme.typography.pxToRem(21)};
      line-height: 28px;
      max-width: 470px;
      padding-bottom: 2.5rem;
    }
  }
  @media only screen and (max-width: 460px) {
    padding: 2.5rem 1.25rem;
  }
`
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(46)};
  line-height: 55px;
  margin-bottom: 0.5rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-bottom: 1rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(62)};
    line-height: 70px;
  }
`

export const TextWrapper = styled.div`
  z-index: 2;
  svg {
    margin-left: 0.5rem;
  }
  .demo-button {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    .demo-button {
      display: block;
    }
  }
`
export const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    min-height: 280px;
    max-height: 400px;
    z-index: 2;
    img {
      object-position: bottom right;
      object-fit: contain !important;
    }
    ${({ theme }) => theme.breakpoints.up("md")} {
      min-height: 500px;
      max-height: unset;
    }
  }

  @media only screen and (max-width: 460px) {
    display: none;
  }
`
export const Logo = styled(Vector)`
  position: absolute;
  z-index: 1;
  max-width: 350px;
  top: 0;
  right: -21px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    right: 4rem;
    max-width: unset;
  }
`
export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  svg {
    color: white;
  }
`
