import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import { getPostLayout } from "../../utils/get-post-layouts-utils"
import BlogHeader from "../../components/blog-header/blog-header.component"
import BlogsNavigation from "../../components/blogs-navigation/blogs-navigation.component"
import ShareSocialMediaIcons from "../../components/share-social-media-icons/share-social-media-icons.component"
import * as S from "./post.template.styles"
import DemoDialog from "../../components/demo-dialog/demo-dialog.component"
import DemoDialogSecond from "../../components/demo-dialog-second/demo-dialog-second.component"
import { isBrowser } from "../../utils/utils"
import moment from "moment/moment"
import SEO from "../../components/seo/seo.component"

export const query = graphql`
  query PostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      postBuilder {
        firstImage {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        layouts {
          ... on WpPost_Postbuilder_Layouts_MiscContentPost {
            ...MiscContentPost
          }
          ... on WpPost_Postbuilder_Layouts_ImageRow {
            ...ImageRow
          }
        }
      }
      id
      uri
      slug
    }

    allWpPost(filter: { id: { nin: [$id] } }) {
      nodes {
        title
        categories {
          nodes {
            name
            slug
          }
        }
        uri
        date
        slug
        featuredImage {
          node {
            altText
            sourceUrl
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  const seo = data.wpPost.seo
  const canonical = `/blog${seo.canonical}`
  const opengraphUrl = `/blog${seo.opengraphUrl}`
  return <SEO data={{ ...seo, canonical, opengraphUrl }} />
}

const Post = ({ data }) => {
  const { id, seo, title, content, slug, featuredImage, date, postBuilder } =
    data.wpPost
  const layouts = postBuilder.layouts || []
  const canonical = `/blog${seo.canonical}`
  const opengraphUrl = `/blog${seo.opengraphUrl}`
  const [dialogName, setDialogName] = useState(null)
  const [open, setOpen] = useState(false)

  // useEffect(() => {
  //   if (isBrowser) {
  //     window.dataLayer = window.dataLayer || []
  //     window.dataLayer.push({ event: "optimize.activate" })
  //   }
  //   const params = new URLSearchParams(window.location.search)
  //   const popup = params.get("name")
  //   const cookieTimestamp = localStorage.getItem("blog-timestamp")
  //   const cookieLocaleTime = moment(new Date(cookieTimestamp * 1000))
  //   const now = moment(new Date())
  //   const relativeTime = moment.duration(now.diff(cookieLocaleTime)).asDays()
  //   if (popup && isBrowser && relativeTime > 28) {
  //     setDialogName(popup)
  //     setTimeout(() => {
  //       setOpen(true)
  //     }, 10000)
  //   }
  // }, [])
  return (
    <Layout seo={{ ...seo, canonical, opengraphUrl }}>
      <BlogHeader
        title={title}
        featuredImage={postBuilder.firstImage}
        date={date}
      />
      <S.CustomContainer>
        <ShareSocialMediaIcons slug={slug} />
        <S.LayoutsWrapper>
          {layouts.map(layout => getPostLayout(layout))}
        </S.LayoutsWrapper>
      </S.CustomContainer>
      <BlogsNavigation blogId={id} />
      {/*{dialogName ? (*/}
      {/*  <>*/}
      {/*    {dialogName === "demo0" && (*/}
      {/*      <DemoDialog*/}
      {/*        open={open}*/}
      {/*        setOpen={setOpen}*/}
      {/*        title={"blog-timestamp"}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    {dialogName === "demo1" && (*/}
      {/*      <DemoDialogSecond*/}
      {/*        open={open}*/}
      {/*        setOpen={setOpen}*/}
      {/*        title={"blog-timestamp"}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
    </Layout>
  )
}

export default Post
